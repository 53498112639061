import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from 'gatsby'
import media from "../../../styles/breakPoints"
import { useIntl } from 'gatsby-plugin-intl';

import Layout from "../../../components/Layout";
import SEO from "../../../components/seo"

import Section from "../../../objects/Section"
import ProductSection from "../../../components/ProductSection"

const WrapSections = styled(Section)`
   background-color: ${props => props.theme.color.grayLight};
   flex-direction: column;
   padding: 0;
   align-items: flex-start;

   ${media.greaterThan("md")`
      padding: 20px 0;
   `}

   ${media.greaterThan("lg")`
      padding: 40px 0;
   `}

`

const ConectoresTomadas = () => {

   const intl = useIntl();

   const content = useStaticQuery(
      graphql`
         query {
            imgCT_Row1: allFile (
               filter: 
                  {  relativeDirectory: { eq: "conectores-tomadas/row1" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgCT_Row2: allFile (
               filter: 
                  {  relativeDirectory: { eq: "conectores-tomadas/row2" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgCT_Row3: allFile (
               filter: 
                  {  relativeDirectory: { eq: "conectores-tomadas/row3" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgCT_Row4: allFile (
               filter: 
                  {  relativeDirectory: { eq: "conectores-tomadas/row4" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            pdfCT_Row1: allFile (
               filter: 
                  {  relativeDirectory: { eq: "conectores-tomadas-row1" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
            pdfCT_Row2: allFile (
               filter: 
                  {  relativeDirectory: { eq: "conectores-tomadas-row2" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
            pdfCT_Row3: allFile (
               filter: 
                  {  relativeDirectory: { eq: "conectores-tomadas-row3" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
         }      
      `
    )
  
   return (
      <Layout>
         <SEO 
            lang={intl.locale}
            title={intl.formatMessage({ id: "navdropdown.item3" })}
            keywords={[ `indústria metalúrgica em São Paulo`, `conector unipolar`, `conector bipolar`, `conector tetrapolar`, `moldado em borracha`, `conector para bateria`, `tomada multipolar`, `serviços pesados`, `tomadas especiais`, `carro torpedo`, `máquina móvel` ]}
         />
         <WrapSections>
            <ProductSection
               tagAs="h1"
               productline="conectorestomadaspage"
               row="contentCT_Row1"
               section={intl.formatMessage({ id: "conectorestomadaspage.contentCT_Row1.section" })}
               element="glide-conectores-tomadas-row1" 
               children={content.imgCT_Row1.edges}
               catalog={content.pdfCT_Row1.edges}
               totalCount={content.pdfCT_Row1.totalCount}
            />
            <ProductSection
               productline="conectorestomadaspage"
               row="contentCT_Row2"
               section={intl.formatMessage({ id: "conectorestomadaspage.contentCT_Row2.section" })}
               element="glide-conectores-tomadas-row2" 
               children={content.imgCT_Row2.edges}
               catalog={content.pdfCT_Row2.edges}
               totalCount={content.pdfCT_Row2.totalCount}
            />
            <ProductSection
               productline="conectorestomadaspage"
               row="contentCT_Row3"
               section={intl.formatMessage({ id: "conectorestomadaspage.contentCT_Row3.section" })}
               element="glide-conectores-tomadas-row3" 
               children={content.imgCT_Row3.edges}
               catalog={content.pdfCT_Row3.edges}
               totalCount={content.pdfCT_Row3.totalCount}
            />
            <ProductSection
               productline="conectorestomadaspage"
               row="contentCT_Row4"
               section={intl.formatMessage({ id: "conectorestomadaspage.contentCT_Row4.section" })}
               element="glide-conectores-tomadas-row4" 
               children={content.imgCT_Row4.edges}
               // catalog={content.pdfCT_Row4.edges}
               totalCount={0}
            />
         </WrapSections>
      </Layout>
   )
}

export default ConectoresTomadas;